import { Select, SelectOption } from "common/otto-ui";
import { SelectFormFieldProps } from "components/form/types";
import { useField } from 'formik';
import { isEqual } from "lodash";
import React from "react";
import { capitalizeFirstLetter } from "utils";

// TODO: Don't rely on lodash._equal to cpare objects, instead try comparing with a single value.
export const FormSelectField = ({ label, options, selectedValue, ...props }: SelectFormFieldProps) => {
  const [field, meta] = useField(props);
  return (
    <div className="grid gap-2">
      <label htmlFor={field.name} className="font-semibold">{label}</label>
      <Select
        className={'border-2 rounded-lg px-2 border-gray-200'}
        name={field.name}
        onChange={field.onChange}
        variant="line"
        value={selectedValue ? options.find(f => isEqual(f.value, field.value))?.label : options.find(f => f.value === field.value)?.label}
        helperText={meta.touched && capitalizeFirstLetter(meta.error)}
        error={!!meta.error}
        placeholder={props.placeholder}
      >
        {options.map((o, k) => (
          <SelectOption key={`${field.name}_${k}`} value={o.value}>
            {o.label}
          </SelectOption>
        ))}
      </Select>
    </div>
  );
};
