import { Image } from "components";
import { MainHeader } from 'components/main-header';
import { ROUTE_ENUM } from 'configuration/data/routers';
import Link from 'next/link';

import styles from './auth-split.module.scss';
import { ReactNode, memo } from 'react';
import { Logo } from 'components/logo';

interface props {
  title?: string;
  /**
   * Image path
   */
  image?: string;
  logoRoute?: ROUTE_ENUM;
  children?: ReactNode;
}

export const AuthSplitLayout = memo(({
  title,
  image = 'static/images/login-01.jpg',
  logoRoute = ROUTE_ENUM.LOGIN,
  children
}: props) => (
  <>
    <MainHeader title={title} />
    <div className={styles.root}>
      <div>
        <div><Link href={logoRoute} prefetch={false}><Logo width={120} /></Link></div>
        <div>
          <div>{children}</div>
        </div>
      </div>
      <div>
        <div>
          <div className={styles.imageText}>
            {/* <div>Take control of your<br />financial health</div> */}
            <div >
              <Logo width={80} contrast />
            </div>
          </div>
          <div className={styles.fader} />
          <Image src={`/${image}`} alt="" fill style={{ objectFit: 'cover' }} unoptimized={false} />
        </div>
      </div>
    </div>
  </>
));
